import { createActionGroup, emptyProps, props } from "@ngrx/store";
import { ValidateSubscription } from "./user.reducer";

export const userAction = createActionGroup({
    source: 'user',
    events: {
        validateSubscription: emptyProps(),
        validateSubscriptionSuccess: props<{data: ValidateSubscription}>(),
        validateSubscriptionFailure: props<{errorMessage: string}>(),

        getUserInfo: emptyProps(),
        getUserInfoSuccess: emptyProps(),
        getUserInfoFailure: emptyProps(),
    }
})